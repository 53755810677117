
import React from 'react';
import { useTranslations } from '@/hooks/useTranslations';
import FeedbackSection from '@/components/feedback/FeedbackSection';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';

const Support = () => {
  const { t } = useTranslations();

  return (
    <div className="container max-w-4xl mx-auto px-4 py-12">
      <h1 className="text-3xl font-bold mb-8 text-center">
        {t('common.support')}
      </h1>
      
      <Card className="mb-12">
        <CardHeader>
          <CardTitle className="text-xl">{t('support.legalInfo')}</CardTitle>
        </CardHeader>
        <CardContent className="space-y-4">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
              <h3 className="font-medium text-lg mb-2">{t('support.legalAddress')}</h3>
              <p>Admiralbogen 41</p>
              <p>80939 München</p>
              <p>Germany</p>
            </div>
            
            <div>
              <h3 className="font-medium text-lg mb-2">{t('support.contactInfo')}</h3>
              <p>
                <span className="font-medium">{t('support.email')}:</span>{' '}
                <a href="mailto:Istorio@gmail.com" className="text-blue-600 hover:underline">
                  Istorio@gmail.com
                </a>
              </p>
              <p>
                <span className="font-medium">{t('support.phone')}:</span>{' '}
                <a href="tel:+491759424376" className="text-blue-600 hover:underline">
                  +49 175 9424376
                </a>
              </p>
            </div>
          </div>
        </CardContent>
      </Card>
      
      {/* Duplicated Feedback Form */}
      <FeedbackSection source="support_page" />
    </div>
  );
};

export default Support;
