
import React from 'react';
import { useTranslations } from '@/hooks/useTranslations';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { Link } from 'react-router-dom';

const Privacy = () => {
  const { t } = useTranslations();

  return (
    <div className="container max-w-4xl mx-auto px-4 py-12">
      <h1 className="text-3xl font-bold mb-8 text-center">
        {t('privacy.title')}
      </h1>
      
      <Card className="mb-8">
        <CardHeader>
          <CardTitle className="text-xl">{t('privacy.lastUpdated')}</CardTitle>
        </CardHeader>
        <CardContent className="prose max-w-none">
          <p className="text-sm text-muted-foreground mb-6">April 8, 2025</p>
          
          <section className="mb-6">
            <h2 className="text-xl font-semibold mb-3">{t('privacy.introduction.title')}</h2>
            <p className="mb-3">
              {t('privacy.introduction.text')}
            </p>
            <p>
              {t('privacy.introduction.noCollection')}
            </p>
          </section>
          
          <section className="mb-6">
            <h2 className="text-xl font-semibold mb-3">{t('privacy.whatWeCollect.title')}</h2>
            <p className="mb-3">
              {t('privacy.whatWeCollect.text')}
            </p>
            <ul className="list-disc pl-6 mb-3">
              <li>{t('privacy.whatWeCollect.language')}</li>
              <li>{t('privacy.whatWeCollect.savedPhrases')}</li>
            </ul>
            <p>
              {t('privacy.whatWeCollect.storage')}
            </p>
          </section>
          
          <section className="mb-6">
            <h2 className="text-xl font-semibold mb-3">{t('privacy.dataSharing.title')}</h2>
            <p>
              {t('privacy.dataSharing.text')}
            </p>
          </section>
          
          <section className="mb-6">
            <h2 className="text-xl font-semibold mb-3">{t('privacy.userRights.title')}</h2>
            <p className="mb-3">
              {t('privacy.userRights.text')}
            </p>
            <p>
              {t('privacy.userRights.control')}
            </p>
          </section>
          
          <section className="mb-6">
            <h2 className="text-xl font-semibold mb-3">{t('privacy.children.title')}</h2>
            <p>
              {t('privacy.children.text')}
            </p>
          </section>
          
          <section className="mb-6">
            <h2 className="text-xl font-semibold mb-3">{t('privacy.changes.title')}</h2>
            <p>
              {t('privacy.changes.text')}
            </p>
          </section>
          
          <section className="mb-6">
            <h2 className="text-xl font-semibold mb-3">{t('privacy.contact.title')}</h2>
            <p className="mb-3">
              {t('privacy.contact.text')}
            </p>
            <p>
              <strong>{t('privacy.contact.email')}: </strong>
              <a href="mailto:Istorio@gmail.com" className="text-blue-600 hover:underline">Istorio@gmail.com</a>
            </p>
          </section>
        </CardContent>
      </Card>
      
      <div className="text-center">
        <Link to="/" className="text-blue-600 hover:underline">
          {t('privacy.backToHome')}
        </Link>
      </div>
    </div>
  );
};

export default Privacy;
